<template>
  <Teleport
    :to="teleport"
    :disabled="!teleport"
  >
    <Transition name="fade">
      <div
        v-if="modelValue"
        class="overlay"
        :style="styleComputed"
        v-bind="$attrs"
      />
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
interface IProps {
  modelValue?: boolean;
  teleport?: string;
  index?: number;
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: false,
  teleport: undefined,
  index: 100,
});

defineOptions({
  inheritAttrs: false,
});

const styleComputed = computed(() => {
  return {
    zIndex: props.index,
  };
});

watch(
  () => props.modelValue,
  (modelValue) => {
    if (modelValue) {
      document.documentElement.classList.add('is-overlay');
    } else {
      document.documentElement.classList.remove('is-overlay');
    }
  }
);

onUnmounted(() => {
  document.documentElement.classList.remove('is-overlay');
});
</script>
