<template>
  <Overlay
    v-if="isActive && !noOverlay"
    v-model="isOpened"
    teleport="body"
    @click="close"
  />

  <Teleport
    to="body"
    :disabled="!isActive"
  >
    <div
      v-bind="$attrs"
      :class="[
        {
          drawer: isActive,
          'is-opened': isActive && isOpened,
        },
      ]"
    >
      <Button
        v-if="isActive"
        class="drawer__close"
        variant="link"
        iconOnly
        iconName="close"
        @click="close(name)"
      />

      <slot />
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import type { IDrawer } from '@types';
import { Button } from '@shared/Button';
import { Overlay } from '@shared/Overlay';

interface IProps {
  name: string;
  position?: 'left' | 'right' | 'down' | 'up';
  noOverlay?: boolean;
  isMultiple?: boolean;
  activeSmaller?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  activeGreater?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const props = withDefaults(defineProps<IProps>(), {
  position: 'left',
  class: undefined,
  activeSmaller: undefined,
  activeGreater: undefined,
});

const { close } = useDrawers();

const $breakpoints = useBreakpoints();

const { activeList, openList } = storeToRefs(useDrawerStore());

const isActive = computed(() => !!activeList.value?.find((drawer: IDrawer) => drawer.name === props.name));

const isOpened = computed(() => openList.value?.includes(props?.name));

const hasInitialization = computed(
  () =>
    (!props.activeSmaller && !props.activeGreater) ||
    !!(props.activeSmaller && $breakpoints.smaller(props.activeSmaller).value) ||
    !!(props.activeGreater && $breakpoints.greater(props.activeGreater).value)
);

const toggleActive = () => {
  if (hasInitialization.value) {
    activeList.value?.push(props);
  } else {
    activeList.value = activeList.value?.filter((drawer: IDrawer) => drawer.name !== props.name);
  }
};

useEventListener(document, 'keydown', (e) => {
  if (isActive.value && e?.code?.toLowerCase() === 'escape') {
    e.preventDefault();
    close();
  }
});

watch(hasInitialization, () => {
  toggleActive();
});

onMounted(() => {
  toggleActive();
});
</script>

<style lang="scss" src="./Drawer.scss" />
